.btn-container {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
}

.btn {
  border: 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 12px 20px;
  transition: 0.3s all;
  min-width: 140px;
}

.btn-primary,
.btn-secondary:hover {
  background: #f15bb5;
  color: white;
  border: 1px solid #f15bb5;
}

.btn-icon-container {
  background-color: rgba(228, 75, 166, 0.62);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
}

.btn-w-icon-container {
  transition: 0.2s all;
}

.btn-secondary,
.btn-primary:not(.disabled):hover {
  border: 1px solid #f15bb5;
  color: #2c2c2c;
  background: white;
}

.btn-w-icon-container .btn-primary,
.btn-w-icon-container:hover .btn-primary {
  background: #e44ba6;
  color: white;
  border: 1px solid #e44ba6;
}

.btn-w-icon-container:hover {
  opacity: 0.8;
}

.btn-disabled {
  border: 1px solid #f15bb5;
  background: #f15bb5;
  opacity: 0.5;
  color: white;
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  .btn-w-icon-container.no-collapse .btn-primary {
    border-radius: 0 5px 5px 0;
    height: 45px;
  }
  .btn-w-icon-container:not(.no-collapse) .btn-icon-container {
    background-color: #e44ba6;
    height: 45px;
    min-width: 45px;
  }
  .btn-w-icon-container:not(.no-collapse) .btn-primary {
    display: none;
  }
}
