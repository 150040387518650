.upload-button {
  width: 171px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #f15bb5;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  cursor: pointer;
  position: relative;
}
.upload-button.edit {
  margin-bottom: 60px;
}

.upload-button p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 0;
}

.upload-button input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-logo {
  width: 12px;
  height: 12px;
  margin-left: 8px;
}
.close-logo-black {
  width: 8px;
  height: 8px;
  margin-left: 8px;
}
.input-label {
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 500;
  margin-bottom: 8px;
}

.input {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.action-label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 24px;
  text-decoration: underline;
  cursor: pointer;
}

.action-label.edit {
  margin-bottom: 16px !important;
}

.attachments {
  display: flex;
  /* margin-bottom: 200px; */
  cursor: pointer;
  align-items: center;
}

.attachments.edit {
  margin-bottom: 16px !important;
}
.attachment-logo {
  width: 16px;
  height: 16px;
}
.attachments p,
.attachments a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #2c2c2c;
  margin: 0 0 0 8px;
}

input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

textarea {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
}

.links {
  margin-bottom: 8px;
}

.links > a {
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #2c2c2c;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.btn-box {
  display: flex;
  position: relative;
  height: 24px;
}

.btn-box .add {
  position: absolute;
  top: 0;
  left: 0;
}
.btn-box .remove {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 600px) {
  .upload-button {
    margin-bottom: 30px;
  }
  .upload-button.edit {
    margin-bottom: 30px;
  }
  .attachments {
    margin-bottom: 160px;
  }
}
