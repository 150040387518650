.topbar {
  background: white;
  width: 100vw;
  max-width: 100vw;
  height: 60px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 8px 50px;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 200px;
  object-fit: scale-down;
  min-width: 140px;
}

.heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;
}

.user-logo {
  display: flex;
  background: #f15bb5;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow {
  margin-left: 5px;
  margin-top: 4px;
}

.user-dropdown {
  display: flex;
  text-align: center;
  cursor: pointer;
  align-items: center;
}

.header-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 0 16px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  width: max-content;
  left: 40px;
  top: -10px;
  z-index: 20;
}

.header-dropdown > a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  color: #2c2c2c !important;
  text-decoration: none !important;
  width: 100%;
}

.header-dropdown > a > a {
  color: #2c2c2c !important;
}

.logout {
  border-top: 1px solid #f15bb5;
  padding-top: 8px;
}

.logout-logo {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .topbar {
    display: flex;
    height: 53px;
    justify-content: space-between;
    padding: 8px 16px;
  }
  .heading,
  .arrow {
    display: none;
  }
  .header-dropdown {
    left: -70px;
  }
}
