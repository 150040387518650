.search-input {
  font-size: 1rem;
  padding: 0;
  min-width: 282px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 12px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  width: 100%;
  max-width: 500px;
  align-items: center;
  height: 100%;
  position: relative;
  padding-left: 43px;
}

.modal {
  position: absolute;
  background: white;
  top: 110%;
  width: 100%;
  padding: 10px;
  display: grid;
  gap: 1px;
  grid-gap: 1px;
  border-radius: 10px;
  box-shadow: 0 10px 20px #444;
  z-index: 10;
}

.modal div {
  transition: 0.2s all;
}

.search-input:focus {
  outline: 0;
}

.searchbar {
  position: relative;
}

.searchbar-icon {
  z-index: 1;
  left: 0px;
  top: 8px;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  width: 45px;
  position: absolute;
}

.search-clear {
  z-index: 1;
  right: 0px;
  top: 0px;
  display: flex;
  height: 45px;
  align-items: center;
  width: 31px;
  position: absolute;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .searchbar input {
    display: initial;
  }

  .searchbar-icon {
    right: 20px;
    position: absolute;
  }

  .mobile-searchbar-icon {
    position: absolute;
    z-index: 15;
  }

  .searchbar-container .searchbar-icon {
    position: absolute;
  }

  .DayPickerInput {
    padding: 0px !important;
  }
}
