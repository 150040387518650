.marker-infowindow {
  width: 380px;
  height: 335px;
}

.marker-infowindow.no-edit {
  height: 290px;
}

.marker-infowindow-img {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

.marker-infowindow-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.marker-infowindow-city-name {
  margin-bottom: 10px;
}

.marker-infowindow-country-name {
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 400;
}

.marker-infowindow-dates {
  font-size: 1rem;
}

.marker-infowindow-actions {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-gap: 10px;
}

.marker-infowindow-clear {
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  padding: 5px;
  display: grid;
  color: white;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 0.8;
  position: absolute;
  padding-right: 7px;
  justify-content: end;
  border-radius: 0 4px 0 0;
  background: linear-gradient(45deg, transparent 50%, #f15bb5 50%);
}

/* map default close button */
button.gm-ui-hover-effect {
  display: none !important;
}
