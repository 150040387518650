.modal-header {
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  background-color: #eeeeee;
  border-radius: 4px 4px 0 0;
  font-weight: 500;
  align-items: center;
}

.modal-close-btn {
  line-height: 19px;
  cursor: pointer;
  font-size: 27px;
  height: 19px;
}

.modal-body {
  padding: 16px 40px 55px;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal input.input {
  margin: 0 10px;
  width: 96%;
}

.modal-body::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10%;
}

@media only screen and (max-width: 600px) {
  .modal-body {
    padding: 16px;
  }
}
