.sidebar-container {
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 400px;
  width: 36vw;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 10;
  transition: 0.3s all;
}

.sidebar-collapsed {
  margin-left: -400px;
}

.sidebar-header-info > div {
  white-space: nowrap;
}

.sidebar-container-collapsed {
  top: 100px;
  left: 40px;
  width: 14rem;
  height: auto;
}

.sidebar-header {
  background: #eeeeee;
  display: flex;
  height: 50px;
  padding: 10px 25px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sidebar-header-info {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  grid-gap: 10px;
  align-items: center;
  width: 100%;
}

.sidebar-collapse-btn {
  transition-delay: 0.2s display;
}

.sidebar-collapse-btn.sidebar-collapse-btn-collapsed {
  display: none;
  opacity: 0;
}

.sidebar-lineitem-destination-dates {
  font-size: 14px;
}

.sidebar-list-container {
  display: grid;
  background: white;
  padding: 25px 25px 40px;
  /* height: calc(100vh - 99px); */
  gap: 20px;
  grid-gap: 20px;
  transition: 0.3s all;
}

.sidebar-container-collapsed .sidebar-list-container {
  opacity: 0;
  display: none;
}

.sidebar-btn-wrapper {
  display: grid;
  gap: 10px;
  grid-gap: 10px;
  align-self: end;
}

.destinations-count {
  padding: 2px 6px;
  color: white;
  background-color: #f15bb5;
  font-size: 0.75rem;
  border-radius: 100px;
  margin-left: 4px;
  justify-self: flex-end;
}

.sidebar-lineitem-destination-count {
  font-size: 2.18rem;
  border-right: 3px solid #f15bb5;
  padding: 0 5px;
  line-height: 2.52rem;
  display: flex;
  align-items: center;
}

.sidebar-listitems-container {
  gap: 60px;
  grid-gap: 60px;
  align-items: flex-start;
  align-content: flex-start;
  display: grid;
  max-height: 100%;
  overflow-y: auto;
}

.sidebar-listitem {
  border-radius: 5px;
  padding: 7px 15px 7px 10px;
  border: 1px solid rgba(3, 98, 186, 0.2);
  position: relative;
}

.sidebar-lineitem-destination-name {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.sidebar-lineitem-edit {
  color: #034ba4;
  align-self: flex-end;
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
}

.sidebar-lineitem-clear {
  top: -1px;
  right: -1px;
  width: 25px;
  height: 25px;
  padding: 2px;
  display: grid;
  color: white;
  cursor: pointer;
  line-height: 0.8;
  position: absolute;
  justify-content: end;
  border-radius: 0 4px 0 0;
  background: linear-gradient(45deg, #fff 50%, #f15bb5 50%);
}

.notes-icon {
  position: absolute;
  right: 80px;
  bottom: 10px;
}

.attach-icon {
  position: absolute;
  right: 50px;
  bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .sidebar-container,
  .sidebar-container-collapsed {
    top: 92px;
    left: 0;
    width: 88vw;
    position: absolute;
  }
  .sidebar-collapsed {
    margin-left: -88vw;
  }

  .marker-infowindow {
    width: auto;
    height: auto;
  }

  .itinerary-sidebar-toggle-btn {
    display: flex !important;
  }
}

.sidebar-list-container.itinerary {
  padding: 25px 25px 60px;
}

.sidebar-toggle-btn,
.itinerary-sidebar-toggle-btn {
  position: absolute;
  right: -20px;
  background-color: #f15bb5;
  width: 20px;
  height: 50px;
  top: 0;
  justify-content: center;
  cursor: pointer;
}

.sidebar-toggle-btn {
  display: flex;
}

.itinerary-sidebar-toggle-btn {
  display: none;
}

.sidebar-collapsed .sidebar-toggle-btn svg,
.sidebar-collapsed .itinerary-sidebar-toggle-btn svg {
  transform: rotate(180deg);
}

.sidebar-items-gap {
  position: absolute;
  top: calc(100% + 5px);
  left: 27px;
}

/* .sidebar-items-gap > span {
  grid-template-rows: 1fr auto;
  height: 52px;
} */

.sidebar-items-gap svg {
  padding: 5px;
  font-size: 30px;
}

.sidebar-items-gap .dotted-line {
  width: 2px;
  border-left: 1px dashed;
  height: 25px;
  margin-left: 14px;
}

@media only screen and (max-width: 600px) {
  .sidebar-lineitem-destination-name {
    font-size: 1rem;
  }
}
