* {
  box-sizing: border-box;
  outline: 0;
  font-family: "Roboto";
}

.app {
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  background: linear-gradient(
    123.65deg,
    #069af2 3.33%,
    #0362ba 50.67%,
    #002d86 94.72%
  );
}

.dashboard {
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  background: white;
}

.dashboard-wrapper {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding: 40px 50px;
}

.trip-dashboard-wrapper {
  height: 100%;
  overflow-y: scroll;
}

.set-pwd-wrapper {
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  width: 100%;
  padding: 40px 50px;
}

.profile-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
}

.side-container {
  width: 20%;
  height: 100%;
  padding-top: 40px;
}

.main-container {
  width: 80%;
  height: 100%;
  padding-right: 20%;
  padding-top: 40px;
}
.heading-type-one {
  margin-bottom: 16px;
  line-height: 28.13px;
  font-size: 24px;
  font-weight: 500;
}
.heading-type-two {
  margin-bottom: 24px;
  line-height: 16px;
  font-size: 18px;
  font-weight: 400;
}

.heading-type-three {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 25px;
  text-align: center;
}
.rp-container {
  width: 420px;
}
.input-container-primary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.after-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inside-container {
  /* display: flex; */
  justify-content: center;
  width: 320px;
  margin: 0;
  padding: 0;
}

.user-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #f15bb5;
  margin: 0 auto 0 auto;
}

.avatar-image-default {
  width: 86px;
  height: 86px;
}

.avatar-image-user {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.dp-upload {
  width: 171px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #f15bb5;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto 0 auto;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.dp-upload p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 0;
}

.dp-upload input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.input-label {
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.input {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.input.select {
  appearance: none;
}
.field {
  margin-bottom: 24px;
}

.flex-1 {
  flex: 1;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-none {
  display: none;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.text-primary {
  color: #36c0ae;
}

.text-center {
  text-align: center;
}

.text-xl {
  font-size: 25px;
}

.text-lg {
  font-size: 20px;
}

.text-md {
  font-size: 16px;
}

.font-500 {
  font-weight: 500;
}

.p-relative {
  position: relative;
}

.ml-1,
.mx-1,
.m-1 {
  margin-left: 0.5rem;
}

.mr-1,
.mx-1,
.m-1 {
  margin-right: 0.5rem;
}

.mt-1,
.my-1,
.m-1 {
  margin-top: 0.5rem;
}

.mb-1,
.my-1,
.m-1 {
  margin-bottom: 0.5rem;
}

.ml-3,
.mx-3,
.m-3 {
  margin-left: 1.2rem;
}

.mr-3,
.mx-3,
.m-3 {
  margin-right: 1.2rem;
}

.mt-3,
.my-3,
.m-3 {
  margin-top: 1.2rem;
}

.mb-3,
.my-3,
.m-3 {
  margin-bottom: 1.2rem;
}

.ml-4,
.mx-4,
.m-4 {
  margin-left: 1.5rem;
}

.mr-4,
.mx-4,
.m-4 {
  margin-right: 1.5rem;
}

.mt-4,
.my-4,
.m-4 {
  margin-top: 1.5rem;
}

.mb-4,
.my-4,
.m-4 {
  margin-bottom: 1.5rem;
}

.pl-4,
.px-4,
.p-4 {
  padding-left: 1.5rem;
}

.pr-4,
.px-4,
.p-4 {
  padding-right: 1.5rem;
}

.pt-4,
.py-4,
.p-4 {
  padding-top: 1.5rem;
}

.pb-4,
.py-4,
.p-4 {
  padding-bottom: 1.5rem;
}

.pl-3,
.px-3,
.p-3 {
  padding-left: 1.2rem;
}

.pr-3,
.px-3,
.p-3 {
  padding-right: 1.2rem;
}

.pt-3,
.py-3,
.p-3 {
  padding-top: 1.2rem;
}

.pb-3,
.py-3,
.p-3 {
  padding-bottom: 1.2rem;
}

.pl-5,
.px-5,
.p-5 {
  padding-left: 2.5rem;
}

.pr-5,
.px-5,
.p-5 {
  padding-right: 2.5rem;
}

.pt-5,
.py-5,
.p-5 {
  padding-top: 2.5rem;
}

.pb-5,
.py-5,
.p-5 {
  padding-bottom: 2.5rem;
}

.pl-5,
.px-5,
.p-5 {
  padding-left: 2.5rem;
}

.pr-5,
.px-5,
.p-5 {
  padding-right: 2.5rem;
}

.pt-5,
.py-5,
.p-5 {
  padding-top: 2.5rem;
}

.pb-5,
.py-5,
.p-5 {
  padding-bottom: 2.5rem;
}

.br-5 {
  border-radius: 5px;
}

.w-100,
.width-100 {
  width: 100%;
}

.w-auto,
.width-auto {
  width: auto;
}

.postition-absolute {
  position: absolute;
}

.postition-relative {
  position: relative;
}

.homeGen {
  position: absolute;
  bottom: 23px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  max-width: 345px;
  white-space: nowrap;
}

.input {
  width: 100%;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid rgba(3, 98, 186, 0.2);
  border-radius: 4px;
}

.share-input {
  width: 415px;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid rgba(3, 98, 186, 0.2);
  border-radius: 4px;
  height: 45px;
}

.share-modal-heading {
  text-transform: capitalize;
  position: absolute;
  width: 45%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.share-modal-item {
  background-color: #36c0ae !important;
  border-radius: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  cursor: pointer;
}

.share-modal-link {
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #9c9c9c;
  border-radius: 5px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  padding: 10px;
}

.itinerary-name {
  position: absolute;
  top: 20px;
  font-weight: 600;
  font-size: 20px;
  left: 50%;
  color: #f15bb5;
  transform: translateX(-50%);
}

.page-title {
  position: absolute;
  top: 15px;
  font-weight: 600;
  font-size: 24px;
  left: 50%;
  color: #f15bb5;
  transform: translateX(-50%);
}

.share-btn {
  position: absolute;
  top: 8px;
  right: 50px;
  z-index: 2;
}

.edit-icon {
  color: #ccc;
  width: 30px;
  height: 30px;
  padding: 7px;
  border-radius: 100px !important;
}

.edit-icon:hover {
  background-color: #ddd;
}

.searchbar-container {
  position: relative;
}

.searchbar-container input {
  display: initial;
}

.searchbar-container .searchbar-icon {
  top: 0;
  right: 0px;
}

.tooltip {
  position: absolute;
  left: 120%;
  top: 50%;
  color: white;
  white-space: nowrap;
  background: #333333;
  border-radius: 2px;
  font-size: 12px;
  display: none;
  transform: translateY(-50%);
  padding: 6px 5px 5px 6px;
}

.tooltip::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  left: 0px;
  top: 50%;
  background: #333333;
  transform: rotate(-45deg) translateY(-50%);
}

div:hover > .tooltip {
  display: initial;
}

.marker-info-start-date
  + .DayPickerInput-OverlayWrapper
  .DayPickerInput-Overlay {
  bottom: -12px;
  left: -26px !important;
}

.marker-info-end-date + .DayPickerInput-OverlayWrapper .editDatePicker {
  bottom: -12px;
  left: -117px !important;
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.action-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;
}

.noTripMessage {
  margin-top: 200px;
  text-align: center;
}

.createTripButton {
  width: 310px;
  margin-left: auto;
  margin-right: auto;
}

.tripBox {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-top: 25px;
  column-gap: 30px;
  row-gap: 30px;
}

.bi-wrapper,
.cp-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 30px;
}

.bi-wrapper {
  border-bottom: 1px dashed #36c0ae;
  padding: 8px 0 16px 0;
  margin-bottom: 40px;
}

.cp-wrapper {
  padding: 8px 0 80px 0;
}

.tripCard {
  border: 1px solid rgba(3, 98, 186, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 315px;
  position: relative;
}

.note-card {
  border: 1px solid rgba(3, 98, 186, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  padding: 16px;
  min-height: 250px;
}

.add-note-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(3, 98, 186, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  padding: 16px;
  min-height: 250px;
}

.add-note {
  text-align: center;
  cursor: pointer;
}

.add-note img {
  height: 40px;
  width: 40px;
}
.add-note p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  margin-top: 15px;
}

.note-heading {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
}

.attach-name {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin: 16px 0 0 8px;
}
.note-content {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  margin: 16px 0 0 0;
  word-break: break-word;
}
.upperpart {
  height: 90%;
}
.lowerpart {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 10%;
}
.lower-date {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
}

.lower-edit {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}

.tripCardBody {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.tripCardImage {
  max-width: -webkit-fill-available !important;
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.deleteItem {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  border-right: solid 20px #f15bb5;
  border-top: solid 20px #f15bb5;
  border-bottom: solid 20px transparent;
  border-left: solid 20px transparent;
}

.deleteIcon {
  height: 12px;
  width: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}

.searchLogo {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 14px;
  left: 18px;
  z-index: 10;
}

.deleteButtons {
  display: flex;
  column-gap: 17px;
}
.search-field {
  padding-left: 45px;
}

.sort,
.filter {
  display: flex;
  margin-left: 25px;
  cursor: pointer;
}

.sort-mobile,
.filter-mobile {
  display: none;
}
.filter-dropdown,
.sort-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  width: max-content;
  left: 0px;
  top: -20px;
  z-index: 20;
}

.filter-dropdown > a,
.sort-dropdown > a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 16px;
  color: #2c2c2c;
  text-decoration: none;
  width: 100%;
}

.dd-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dd-list li {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding: 8px;
}

.dd-list li:hover {
  background: rgba(241, 91, 181, 0.3);
}

.search-box {
  position: relative;
}

.action-bar {
  display: flex;
}
.trip-sidebar {
  width: 25%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.action-return {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #eeeeee;
  padding: 16px 15px 16px 24px;
}

.action-box p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2c2c2c;
  margin: 0;
}
.action-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.trip-detail-wrapper {
  padding: 26px 24px 26px 32px;
  width: 100%;
  height: 100%;
}

.action-logos {
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

.trip-main {
  width: 75%;
  padding: 40px 32px;
}

.box-heading {
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  color: #2c2c2c;
  margin: 0;
}
.box-sub-heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(44, 44, 44, 0.5);
  margin: 0;
  margin-top: 8px;
}

.notes-box {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 30px;
  column-gap: 30px;
  row-gap: 30px;
}

.sidebar-container {
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 400px;
  width: 36vw;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 10;
  transition: 0.3s all;
}

.sidebar-collapsed {
  margin-left: -400px;
}

.sidebar-header-info > div {
  white-space: nowrap;
}

.sidebar-container-collapsed {
  top: 100px;
  left: 40px;
  width: 14rem;
  height: auto;
}

.sidebar-toggle-button {
  position: absolute;
  right: -20px;
  background-color: #f15bb5;
  width: 20px;
  height: 50px;
  top: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.sidebar-toggle-button svg {
  transform: rotate(180deg);
}

.sidebar-toggle-button {
  display: flex;
}

.trip-sidebar-mobile {
  display: none;
}

.dropdown-menu.show {
  z-index: 100;
}

.action-buttons {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 20px;
  padding-top: 16px;
}

.action-buttons.primary {
  display: none;
}

.dd-country,
.dd-state {
  position: relative;
  cursor: pointer;
}
.arrow-logo {
  position: absolute;
  width: 10px;
  height: 5px;
  top: 17px;
  right: 15px;
}

/* .btn-box {
  display: flex;
  justify-content: space-between;
} */

.trip-sidebar .searchbar-container {
  padding: 24px 24px 0 32px;
}
.pass-wrapper {
  position: relative;
}

.pass-wrapper i {
  position: absolute;
  top: 37px;
  right: 15px;
}

.pass-wrapper .profile {
  top: 43px;
}

.pass-wrapper i:hover {
  color: #00fcb6;
  cursor: pointer;
}
/*********************************** Mobile Styling ******************************************/

@media only screen and (max-width: 600px) {
  .trip-sidebar-mobile .searchbar-container {
    padding: 16px 16px 0 16px;
  }
  .notes.sidebar-container {
    background: white;
  }
  .action-buttons {
    padding-top: 6px;
  }
  .action-buttons.primary {
    display: grid;
    padding-bottom: 30px;
  }
  .trip-sidebar-mobile {
    display: flex;
  }
  .empty-div {
    display: none;
  }
  .trip-detail-wrapper {
    background: white;
    padding: 25px 25px 40px;
    /* height: calc(100vh - 99px); */
    gap: 20px;
    grid-gap: 20px;
    transition: 0.3s all;
  }
  .trip-sidebar {
    display: none;
  }

  .trip-main {
    width: 100%;
    padding: 60px 16px;
  }

  .dashboard-dd {
    display: none;
  }

  .search-box {
    width: 100%;
  }

  .notes.sidebar-container {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .tripBox {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 25px;
  }

  .bi-wrapper,
  .cp-wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 15px;
  }

  .notes-box {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 25px;
  }
  .dashboard-wrapper {
    padding: 40px 15px;
  }

  .set-pwd-wrapper {
    padding: 40px 15px;
  }

  .profile-wrapper {
    display: block;
    padding: 40px 15px;
  }

  .side-container {
    width: 100%;
    height: fit-content;
    padding-bottom: 40px;
  }

  .field {
    margin-bottom: 16px;
  }

  .main-container {
    width: 100%;
    height: 100%;
    padding-right: 0;
    padding-top: 0;
  }

  .share-input {
    width: 100%;
  }
  .searchbar-container {
    padding: 0;
  }
  .action-text {
    display: none;
  }
  .createTripButton {
    width: 100%;
  }
  .tripCard {
    max-width: 100%;
  }

  .sort-mobile,
  .filter-mobile {
    display: flex;
    margin-left: 25px;
    cursor: pointer;
  }

  .itinerary-strip {
    position: absolute;
    width: 100vw;
    height: 39px;
    color: white;
    background-color: #333333;
    top: 53px;
    z-index: 1;
  }
  .itinerary-name {
    position: absolute;
    top: 8px;
    font-weight: 600;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 20px;
    transform: none;
  }
  .page-title-strip {
    position: absolute;
    width: 100vw;
    height: 39px;
    color: white;
    background-color: #333333;
    top: 53px;
    z-index: 1;
  }
  .page-title {
    position: absolute;
    top: 8px;
    font-size: 18px;
    font-weight: 600;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 50%;
    margin-left: -45px;
    transform: none;
  }
  .share-btn {
    transform: scale(0.7);
    top: -45px;
    width: 45px;
    height: 45px;
    right: -5px;
  }

  .homeGen {
    padding: 13px 0;
  }

  .searchbar-container input {
    position: initial;
    min-width: auto;
  }

  .modal-header .searchbar-container input {
    margin-bottom: auto;
  }

  .marker-infowindow-country-name {
    font-size: 22px;
  }

  .marker-infowindow-dates {
    padding-top: 20px;
    padding-left: 10px;
    white-space: nowrap;
  }

  .itinerary-add {
    position: absolute;
    right: 6px;
    top: 4px;
  }

  .share-trip {
    position: absolute;
    right: 56px;
    top: 4px;
  }

  .datetime-modal {
    max-width: 100vw !important;
    top: 0% !important;
    transform: translate(-50%, 0%) !important;
    height: 100vh;
    align-content: flex-start;
  }
  div:hover > .tooltip {
    display: none;
  }

  .marker-infowindow {
    padding-right: 10px;
    padding-bottom: 20px;
    height: 370px !important;
  }

  .marker-infowindow.no-edit {
    height: 280px !important;
  }

  .gm-style-iw {
    min-width: 350px !important;
  }

  .rp-container {
    width: 100%;
  }

  .after-message-container {
    width: 100%;
  }

  .bi-wrapper {
    border-bottom: 1px dashed #36c0ae;
    padding: 8px 0 6px 0;
    margin-bottom: 30px;
  }
}
