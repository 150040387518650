.datetime-modal-city-img {
  width: 100%;
  object-fit: cover;
  height: 180px;
  border-radius: 5px;
}

.datetime-modal-city-name {
  margin: 24px 0;
  font-weight: 500;
}

.datetime-modal-header {
  border-bottom: 1px dashed #36c0ae;
}

.date-wrapper {
  border: 1px solid rgba(3, 98, 186, 0.2);
  padding: 15px 12px;
  border-radius: 5px;
  flex: 1;
  height: 45px;
  margin-bottom: 40px;
}

.DayPickerInput input {
  border: 0;
  font-size: 14px;
  padding-left: 10px;
  width: 100%;
}

.DayPicker-Caption {
  text-align: center !important;
}

.DayPicker-NavButton--prev {
  margin-right: calc(100% - 4rem) !important;
}

.DayPickerInput input:focus {
  outline: 0;
}

.DayPickerInput
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.DayPickerInput .DayPicker-Day {
  border-radius: 0 !important;
}
.DayPickerInput .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.DayPickerInput .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPickerInput .DayPicker-Day {
  border-radius: 0 !important;
}

.DayPickerInput-Overlay {
  bottom: 30px;
  left: -34px !important;
}

.datetime-modal-end-date + .DayPickerInput-OverlayWrapper .editDatePicker {
  bottom: 30px;
  left: -95px !important;
}

@media only screen and (max-width: 600px) {
  .datetime-modal-end-date + .DayPickerInput-OverlayWrapper .editDatePicker {
    bottom: 30px;
    left: -160px !important;
  }
  .editDatePicker {
    left: -160px !important;
  }
  .datetime-modal-body > p {
    font-size: 20px;
  }
}
